<template>
  <div class="wgcna-query-result">
    <a-spin :spinning="spinning" tip="Loading...">
      <SearchInput v-model="searchValue" :data-source="searchDataSource"
                   @search="handleTopSearch" style="width: 100%;"
                   placeholder="Example:Key words eg. liver Project ID eg.GSE117441"/>

      <div class="wgcna-query-result-detail">
        <div class="wgcna-query-result-detail-item">
          <p>Gse_acc</p>
          <a :href="'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=' + this.wgcnaData.gse_acc"
             target="_blank">{{ this.wgcnaData.gse_acc }}</a>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Overall_design</p>
          <div>{{ this.wgcnaData.overall_design }}</div>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Study_title</p>
          <div>{{ this.wgcnaData.study_title }}</div>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Summary</p>
          <div>{{ this.wgcnaData.summary }}</div>
        </div>
      </div>

      <div class="wgcna-query-result-pdf-list">
        <div v-if="filePath.heatmap">
          <img :src="filePath.heatmap" alt="step5-Module-trait-relationships" @click="pdfVisible = true;"/>
        </div>
      </div>

      <template v-if="categories.length > 0">
        <div class="wgcna-query-result-module-select">
          <div>
            <span>Choose module：</span>
            <a-select size="large" :default-value="currentCategory.name" style="width: 200px" @change="changeCategory">
              <a-select-option v-for="i in categories" :key="i.name">
                {{ i.name }}
              </a-select-option>
            </a-select>
          </div>

          <a-tag color="orange" @click="downloadFile(filePath.folder + currentCategory.interactions)">
            <a-icon type="cloud-download"/>
            download
          </a-tag>
        </div>

        <a-table :columns="columns"
                 size="middle"
                 bordered
                 :rowKey="(record,index)=>index"
                 :pagination="pagination"
                 :data-source="tableData" :scroll="{x:true}">
          <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 0.5rem;"
          >
            <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`Search ${column.title}`"
                :value="selectedKeys[0]"
                size="small"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >Search
            </a-button>
            <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
            >Reset
            </a-button>
          </div>
          <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template slot="customRender" slot-scope="text, record, index, column">
                <span v-if="searchText && searchedColumn === column.dataIndex">
                    <template
                        v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                        <mark
                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                            :key="i"
                            class="highlight"
                        >{{ fragment }}</mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
            <template v-else>{{ text }}</template>
          </template>
        </a-table>

        <div class="graph-group">
          <a-spin tip="Loading..." :spinning="spinning">
            <div class="graph-opera">
              <div class="graph-select">
                <span>mirnaid：</span>
                <a-select size="large" v-model="currentLegend" style="width: 220px" @change="legendChart">
                  <a-select-option v-for="i in this.legendData" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </div>
            </div>

            <Chart echarts-id="WGCNA-relation-graph" :height="chartHeight" :width="chartWidth" :option="chartOption"/>
          </a-spin>

          <a-tabs default-active-key="0">
            <a-tab-pane v-if="currentCategory['GO_BP']" key="0" tab="GO-BP">
              <img :src="filePath.folder + currentCategory['GO_BP']" class="gse_acc-img" alt="GO-BP">
            </a-tab-pane>
            <a-tab-pane v-if="currentCategory['GO_CC']" key="1" tab="GO-CC">
              <img :src="filePath.folder + currentCategory['GO_CC']" class="gse_acc-img" alt="GO-CC">
            </a-tab-pane>
            <a-tab-pane v-if="currentCategory['GO_MF']" key="2" tab="GO-MF">
              <img :src="filePath.folder + currentCategory['GO_MF']" class="gse_acc-img" alt="GO-MF">
            </a-tab-pane>
            <a-tab-pane v-if="currentCategory['KEGG']" key="3" tab="KEGG">
              <img :src="filePath.folder + currentCategory['KEGG']" class="gse_acc-img" alt="KEGG">
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
    </a-spin>

    <a-modal v-model="pdfVisible" :footer="null" centered width="max-content">
      <img :src="filePath.heatmap" alt="step5-Module-trait-relationships" style="height:90vh;"/>
    </a-modal>
  </div>
</template>

<script>
import Chart from "@/components/Chart.vue";
import MicRNATargetGenes from "@/chartsOptions/micRNATargetGenes";
import {parseCsv, downloadFile} from "@/utils/csv.js";
import pdf from 'vue-pdf';
import bus from '@/utils/bus';
import SearchInput from "@/components/SearchInput/index.vue";
import {fetchMiRNAProjectList} from "@/request/niu_api";

let nodes = [];

export default {
  name: "micRnaWgcnaQueryResult",
  components: {
    SearchInput,
    pdf, Chart
  },
  data() {
    return {
      searchValue: "",
      searchDataSource:[],
      wgcnaData: {},
      categories: [],
      currentCategory: {},
      spinning: true,
      gse_acc: "",
      filePath: {
        folder: "",
        json: "",
        heatmap: ""
      },
      columns: [],
      tableData: [],
      pagination: {
        pageSize: 10,
        showSizeChanger: true,
        showTotal(total) {
          return `Total ${total} items`
        },
      },
      /* 表格搜索 */
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      /* 表格搜索 End */
      legendData: [],
      currentLegend: "",
      chartOption: null,
      pdfVisible: false,

      chartHeight: "100%",
      chartWidth: "100%"
    }
  },
  created() {
    this.gse_acc = this.$route.query.gse_acc;
    this.wgcnaData = JSON.parse(window.localStorage.getItem('current_mirna_wgcna_data'));
    const folder = this.common.source_url + `bged2-mir-wgcna/${this.gse_acc}/`;
    this.filePath = {
      folder: folder,
      json: `${folder}summary.json`,
      heatmap: `${folder}step5-Module-trait-relationships.png`,
    }

    fetch(this.filePath.json).then(res => res.json()).then(res => {
      this.currentCategory = res[0];
      this.categories = res;

      this.txtLoad();
    })

    fetchMiRNAProjectList({
      "search": "",
      "page": 1,
      "limit": 1000,
      "sort": "gse_acc",
      "order": "asc"
    }).then(res => {
      if (res.code === 0) {
        this.searchDataSource = res.data.data.map(item => ({
          ...item,
          key:item.gse_acc,
          value:item.gse_acc
        }));
      } else {
        this.$message.error(res.errMsg)
      }
    })
  },
  methods: {
    downloadFile,
    handleTopSearch() {
      this.$router.push({path: "/miRNA-WGCNA", query: {search: this.searchValue}})
    },
    //表格搜索
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    //重置搜索
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
    txtLoad() {
      this.spinning = true;

      this.columns = [];

      parseCsv(this.filePath.folder + this.currentCategory.interactions, (csv) => {
        Object.keys(csv[0]).forEach(item => {
          let obj = {
            title: item,
            dataIndex: item,
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender'
            },
            onFilter: (value, record) => record[item].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus();
                });
              }
            }
          }
          this.columns.push(obj)
        })

        this.tableData = csv;

        this.generateChart(csv);

        this.spinning = false;
      });
    },
    generateChart(data) {
      MicRNATargetGenes.series[0].data = [];
      MicRNATargetGenes.series[0].links = [];
      MicRNATargetGenes.series[0].categories = [];
      this.chartOption = MicRNATargetGenes;

      this.legendData = [];
      this.currentLegend = data[0].mirnaid;
      nodes = [];

      MicRNATargetGenes.title.text = "";
      MicRNATargetGenes.series[0].name = "基因模块关系图";
      MicRNATargetGenes.series[0].force = {
        edgeLength: [20,120],
        repulsion: 100,//斥力因子。该值越大则节点之间的距离越远。
        gravity: 0.2 //节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
      };
      MicRNATargetGenes.series[0].left = 100;
      MicRNATargetGenes.series[0].right = 100;
      MicRNATargetGenes.series[0].categories.push({
        name: "target",
      });
      MicRNATargetGenes.series[0].data.push({
        name: this.currentLegend,
        category: this.currentLegend
      });

      data.forEach((node) => {
        if (!this.legendData.includes(node.mirnaid)) {
          MicRNATargetGenes.series[0].categories.push({
            name: node.mirnaid,
            itemStyle: {
              color: "#FF9900"
            }
          })

          this.legendData.push(node.mirnaid);
        }

        if (nodes.findIndex(item => item.name === node.target_gene) === -1) {
          const n = {
            name: node.target_gene,
            category: "target",
            mirnaid: node.mirnaid
          }

          if (node.mirnaid === this.currentLegend) {
            MicRNATargetGenes.series[0].data.push(n);
          }

          nodes.push(n);
        }

        MicRNATargetGenes.series[0].links.push({
          source: node.mirnaid,
          target: node.target_gene,
        });
      })

      MicRNATargetGenes.toolbox.feature.myDownload.onclick = () => {
        this.echartsExportImg();
      }

      this.computedXY();

      this.chartOption = MicRNATargetGenes;
    },
    computedXY(){
      const xNum = Math.ceil(Math.sqrt(MicRNATargetGenes.series[0].data.length));
      const yNum = Math.ceil(MicRNATargetGenes.series[0].data.length / xNum);
      const CanvasWidth = document.getElementById("WGCNA-relation-graph").offsetWidth;
      const CanvasHeight = document.getElementById("WGCNA-relation-graph").offsetHeight;
      //x,y轴上的间隔
      const xGap = Math.floor(CanvasWidth / xNum);
      const yGap = Math.floor(CanvasHeight / yNum);

      const getXY = (nodeData, index) => {
        //Math.ceil(index / xNum)获取在第几行
        nodeData.x = (index % xNum) * xGap;
        nodeData.y = Math.ceil(index / xNum) * yGap;

        return nodeData;
      }

      MicRNATargetGenes.series[0].data = MicRNATargetGenes.series[0].data.map(getXY);
    },
    echartsExportImg() {
      bus.$emit("globalLoading", {bool: true, tip: "正在下载中..."});

      const convertBase64UrlToBlob = (base64) => {
        const parts = base64.dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; i++) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
      };

      const myChart = this.$echarts.getInstanceByDom(
          document.getElementById("WGCNA-relation-graph")
      );

      this.chartHeight = "450vh";
      this.chartWidth = "450vw";
      myChart.resize();

      myChart.setOption({
        series: [
          {
            force: {
              repulsion: 10,
            }
          }
        ]
      })

      setTimeout(() => {
        //下载结束时执行
        const close = () => {
          this.chartHeight = "100%";
          this.chartWidth = "100%";

          setTimeout(() => {
            myChart.setOption({
              series: [
                {
                  force: {
                    repulsion: 100,
                  }
                }
              ]
            })
            myChart.resize();
            bus.$emit("globalLoading", {bool: false, tip: "正在下载中..."})
          }, 1500)
        }

        myChart.resize();
        const url = myChart.getConnectedDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff", // 图表背景色
          excludeComponents: [// 保存图表时忽略的工具组件,默认忽略工具栏
            'toolbox'
          ],
          type: "png", // 图片类型支持png和jpeg
        });
        const a = document.createElement('a');
        a.download = `MicRNA-WGCNA-relation-graph.png`;
        a.target = '_blank';
        a.href = url;
        // Chrome and Firefox
        if (typeof MouseEvent === 'function') {
          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          a.dispatchEvent(evt);
          close();
        } else {
          // IE
          const base64 = {
            dataURL: url,
            type: 'png',
            ext: 'png',
          };
          const blob = convertBase64UrlToBlob(base64);
          // 下载
          window.navigator.msSaveBlob(blob, `MicRNA-WGCNA-relation-graph.png`);
          close();
        }
      }, 1500)
    },
    legendChart(mirnaid) {
      this.currentLegend = mirnaid;
      MicRNATargetGenes.series[0].data = [];
      MicRNATargetGenes.series[0].data.push({
        name: this.currentLegend,
        category: this.currentLegend
      });

      nodes.forEach(node => {
        if (node.mirnaid === this.currentLegend) {
          MicRNATargetGenes.series[0].data.push(node);
        }
      })

      this.computedXY();

      this.chartOption = MicRNATargetGenes;
    },
    changeCategory(c) {
      this.currentCategory = this.categories.find(item => item.name === c);

      this.txtLoad();
    },
  },
  beforeDestroy() {
    bus.$off("echartInitFinish");
  }
}
</script>

<style scoped lang="scss">
.wgcna-query-result {
  background: #fff;
  width: 80rem;
  margin: 1.8rem 8rem;
  padding: 2rem;

  &-detail {
    margin-top: 1rem;

    &-item {
      margin-bottom: 2rem;

      p {
        margin-bottom: 0 !important;
        height: 37px;
        font-size: 21px;
        font-weight: 600;
        color: #333333;
        line-height: 37px;
      }

      div, a {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 37px;
      }
    }
  }

  &-pdf-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    div {
      width: 37rem;
      border-radius: 13px;
      border: 1px solid #E6E6E6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        height: 37px;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
        display: inline-block;
        margin: 1rem 0;
      }

      img {
        width: 25rem;
      }
    }
  }

  &-module-select {
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-tag {
      cursor: pointer;
      height: 33px;
      line-height: 33px;
      font-size: 16px;
    }

    & > div > span {
      height: 23px;
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      line-height: 19px;
    }
  }
}

.graph-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  & > div {
    width: 36rem;
    height: calc(60vh + 0.5rem + 120px);
    padding: 1rem 2rem;
    border-radius: 13px;
    border: 1px solid #E6E6E6;
    overflow: hidden;

    ::v-deep .ant-spin-container {
      height: 100%;
    }
  }

  .graph-opera {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > div {
      display: flex;
      align-items: center;

      & > span {
        width: 70px;
      }

      ::v-deep .ant-slider {
        width: 9rem;
      }
    }
  }

  .graph-select {
    width: 300px;
    margin: 0.5rem 0 20px 0;
  }
}

.gse_acc-img {
  width: 100%;
}
</style>