export default {
    title: {
        text: 'miRNA target genes'
    },
    tooltip: {},
    grid:{
        containLabel:true,
        bottom:0
    },
    toolbox:{
        show:true,
        right:20,
        itemSize:20,
        feature: {
            restore: {
                icon:"image:///images/echarts-toolbox-refresh.png"
            },
            myDownload: {
                show: true,
                title: "下载",
                icon: 'image:///images/echarts-toolbox-download.png'
            }
        }
    },
    animationDuration: 1500, //数据更新动画的时长。
    animationEasingUpdate: 'quinticInOut', //数据更新动画的缓动效果。
    series: [
        {
            name: "miRNA target genes",
            type: 'graph',
            layout: 'force', //force,none,circular
            animation: false,
            roma: true,
            zoom:1,
            label: {
                show: true,
                // position: 'top',
                formatter: '{b}',
                color:"#fff",
                textShadowColor:"#5470C6",
                textShadowBlur:3,
                textBorderColor:"#000000",
                textBorderWidth:1,
            },
            data: [],
            categories: [],
            force: {
                edgeLength: [20,120],
                repulsion: 100,//斥力因子。该值越大则节点之间的距离越远。
                gravity: 0.2 //节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
            },
            // left:100,
            // right:100,
            links: [],
            symbolSize: 30,
            roam: true, //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            nodeScaleRatio: 0.6, //鼠标漫游缩放时节点的相应缩放比例，当设为0时节点不随着鼠标的缩放而缩放
            draggable: false, //节点是否可拖拽，只在使用力引导布局(layout: 'force',)的时候有用。
            focusNodeAdjacency: true, //是否在鼠标移到节点上的时候突出显示节点以及节点的边和邻接节点。
            //edgeSymbol: ['circle', 'arrow'], //边两端的标记类型，可以是一个数组分别指定两端，也可以是单个统一指定。默认不显示标记，常见的可以设置为箭头，如下：edgeSymbol: ['circle', 'arrow']
            //edgeSymbolSize: 10, //边两端的标记大小，可以是一个数组分别指定两端，也可以是单个统一指定。
        }
    ]
}