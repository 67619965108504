<script>
export default {
  name: "search-input",
  props:{
    value:{
      type: String,
      default: ""
    },
    dataSource:{
      type:Array,
      default:() => []
    },
    placeholder:{
      type:String,
      default:"input search key"
    },
    className:{
      type:String,
      default:""
    }
  },
  methods:{
    onInput(searchText,option){
      return option.key.toUpperCase().indexOf(searchText.toUpperCase()) !== -1;
    },
    handleInput(v){
      this.$emit('input',v);
    },
    handleSelect(v){
      this.$emit('input',v);
      this.$nextTick(()=>{
        this.$refs.autoRef.blur();
        this.$emit('search', v);
      })
    },
    handleSearch(){
      this.$emit('search', this.value)
    },
  }
}
</script>

<template>
  <a-auto-complete :value="value" @change="handleInput" @select="handleSelect"
                   autoFocus option-label-prop="value" ref="autoRef"
                   :filterOption="onInput" :defaultOpen="false" class="es-complete">
    <template slot="dataSource">
      <a-select-option v-for="item in dataSource" :key="item.key">
        {{item.value}}
      </a-select-option>
    </template>

    <a-input allow-clear class="es-search" :class="className" :placeholder="placeholder" >
      <a-icon slot="prefix" style="font-size: 30px;color:#cacaca;" type="search"/>
      <div slot="suffix">
        <a-button class="es-search-btn" type="primary" shape="round" @click="handleSearch">search</a-button>
      </div>
    </a-input>
  </a-auto-complete>
</template>

<style scoped lang="scss">
.es-complete{
  width: 100%;
  height: 60px;
}

.es-search{
  width: 100%;
  height: 60px;

  ::v-deep .ant-input {
    height: 60px;
    border-radius: 30px;
    padding-left: 50px;
    padding-right: 120px;
    font-size: 18px;
  }

  &-btn {
    background: #097F35;
    border: 0;
    height: 52px;
    width: 106px;
    position: relative;
    left: 6px;
    font-size: 16px;
  }

  ::v-deep .ant-input-clear-icon {
    font-size: 20px;
  }
}
</style>